var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "outer" },
      [
        _c(
          "el-form",
          { attrs: { "label-width": "100px" } },
          [
            _c(
              "el-form-item",
              { attrs: { label: "店铺名称", required: "" } },
              [
                _c("el-input", {
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: "请输入店铺名称" },
                  model: {
                    value: _vm.name,
                    callback: function ($$v) {
                      _vm.name = $$v
                    },
                    expression: "name",
                  },
                }),
              ],
              1
            ),
            _c("el-form-item", { attrs: { label: "店铺地址", required: "" } }, [
              _c(
                "div",
                { staticClass: "store-address" },
                [
                  _c("el-cascader", {
                    attrs: {
                      props: _vm.siteOptions,
                      placeholder: "请选择省市区",
                      options: _vm.siteArr,
                      clearable: "",
                    },
                    model: {
                      value: _vm.siteValue,
                      callback: function ($$v) {
                        _vm.siteValue = $$v
                      },
                      expression: "siteValue",
                    },
                  }),
                  _c("span", { staticClass: "space" }),
                  _c("el-input", {
                    staticStyle: { "max-width": "440px" },
                    attrs: { placeholder: "请填写详细地址" },
                    model: {
                      value: _vm.addressDetail,
                      callback: function ($$v) {
                        _vm.addressDetail = $$v
                      },
                      expression: "addressDetail",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "el-form-item",
              { attrs: { label: "店铺头像", required: "" } },
              [
                _c("load-img", {
                  attrs: { data: _vm.data, "max-size": 2097152 },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "店铺招牌", required: "" } },
              [
                _c("p", { staticClass: "remark" }, [
                  _vm._v(
                    " 店铺招牌背景图片呈渐变显示，请在设计时注意整体效果。"
                  ),
                  _c("br"),
                  _vm._v(
                    " 尺寸为750×580，建议400KB左右，支持类型为jpg和png。 "
                  ),
                ]),
                _c("cut-img", {
                  attrs: { url: _vm.shopSign.url },
                  on: { change: _vm.changeAvatar },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "店主头像", required: "" } },
              [
                _c("p", { staticClass: "remark" }, [
                  _vm._v(" 将展示在“农民说”的农民信息区，建议尺寸80×80。 "),
                ]),
                _c("load-img", {
                  attrs: { data: _vm.headData, "max-size": 2097152 },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "店主简介", required: "" } },
              [
                _c("el-input", {
                  staticStyle: { width: "200px" },
                  attrs: { placeholder: "请输入简介" },
                  model: {
                    value: _vm.description,
                    callback: function ($$v) {
                      _vm.description = $$v
                    },
                    expression: "description",
                  },
                }),
                _c("p", { staticClass: "remark" }, [
                  _vm._v(
                    " 将展示在“农民说”的农民信息区，建议文字精简并突出优势。 "
                  ),
                ]),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "店主承诺", required: "" } },
              [
                _c("el-input", {
                  staticStyle: { width: "200px" },
                  attrs: {
                    type: "textarea",
                    placeholder: "请输入承诺语",
                    maxlength: "30",
                  },
                  model: {
                    value: _vm.commitment,
                    callback: function ($$v) {
                      _vm.commitment = $$v
                    },
                    expression: "commitment",
                  },
                }),
                _c("p", { staticClass: "remark" }, [_vm._v(" 仅30个字以内 ")]),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "商家资质", required: "" } },
              [
                _c("p", { staticClass: "remark" }, [
                  _vm._v(" 必传"),
                  _c("b", [_vm._v("《营业执照》")]),
                  _vm._v(", 有的话请上传"),
                  _c("b", [_vm._v("《食品经营许可证》")]),
                ]),
                _c("load-img", {
                  attrs: { data: _vm.aptitude, "max-size": 3145728, max: "5" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "el-button",
          {
            staticStyle: { width: "110px", margin: "50px 100px" },
            attrs: { type: "primary" },
            on: { click: _vm.submitStore },
          },
          [_vm._v(" 提交 ")]
        ),
        _vm.type === "admin"
          ? _c(
              "el-button",
              { staticStyle: { width: "110px" }, on: { click: _vm.backGo } },
              [_vm._v(" 返回 ")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }