<template>
  <div class="upload-box">
    <div class="avatar-uploader-box">
      <!-- 方框样式 -->
      <el-upload
        v-if="type==='avatar' && !imageUrl"
        ref="avatarUploader"
        :action="$api.nb_common.newUpload"
        class="avatar-uploader"
        :show-file-list="false"
        :on-change="handleAvatarChange"
        :auto-upload="false"
        accept="image/*"
      >
        <div
          v-if="!imageUrl"
          v-loading="loading"
          element-loading-text="上传中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          style="line-height:30px;"
        >
          <i class="el-icon-plus avatar-uploader-icon"></i>
        </div>
      </el-upload>

      <!-- 上传的图片 -->
      <img
        v-if="imageUrl"
        :src="imageUrl"
        class="avatar"
        @mouseover.stop="isShowPopup = true"
      />

      <!-- 显示查看和删除的按钮弹窗 -->
      <div
        v-show="imageUrl && isShowPopup"
        class="avatar-uploader-popup"
        @mouseleave="isShowPopup = false"
      >
        <i
          class="el-icon-zoom-in"
          @click="dialogVisible = true"
        ></i>
        <i
          class="el-icon-delete"
          @click="delImageUrl"
        ></i>
      </div>
    </div>

    <!-- 查看大图 -->
    <el-dialog
      title="图片查看"
      center
      :visible.sync="dialogVisible"
      append-to-body
    >
      <img
        width="100%"
        :src="imageUrl"
        alt
      />
    </el-dialog>

    <!-- 裁剪图片 start-->
    <vue-cropper-item
      ref="VueCropperItem"
      @confirm="confirmCropper"
    />
    <!-- 裁剪图片 end-->
  </div>
</template>

<script>
import vueCropperItem from './vueCropperItem'
import { upload } from '@/utils/oss'
export default {
  name: 'CutImg',
  components: {
    vueCropperItem
  },
  props: {
    type: {
      type: String,
      default: 'avatar'
    },
    url: {
      type: String,
      default: ''
    },
    autoCropWidth: {
      type: Number,
      default: 275
    },
    autoCropHeight: {
      type: Number,
      default: 206
    },
    fixedBox: {
      type: Boolean,
      default: false
    },
    isCompress: { // 是否压缩
      type: Boolean,
      default: true
    },
    compress: { // 压缩率
      type: String,
      default: '0.8'
    }
  },
  data() {
    return {
      loading: false,
      isShowPopup: false,
      dialogVisible: false,
      isProgress: false,
      percentage: 0,
      imageUrl: '',
      maxSize: 2100000
    }
  },
  watch: {
    url() {
      this.imageUrl = this.url
    }
  },
  created() {
    this.imageUrl = this.url
  },
  methods: {
    // 上传之前
    async beforeAvatarUpload(file) {
      if (file.size > this.maxSize) {
        this.$message.error('图片体积过大')
        return false
      }
    },

    // 上传改变
    async handleAvatarChange(file, fileList) {
      await this.beforeAvatarUpload(file)
      this.showVueCropperItem(file)
    },

    // 删除图片
    delImageUrl() {
      this.imageUrl = ''
      this.$emit('change', '')
    },

    // 显示裁剪框
    showVueCropperItem(file) {
      this.$refs.VueCropperItem.init()
      this.$refs.VueCropperItem.fileName = file.name

      this.$refs.VueCropperItem.options.img = URL.createObjectURL(file.raw)
      this.setVueCropperOptions()
    },

    // 确认裁剪后上传
    async confirmCropper(file) {
      this.loading = true
      await upload(file).then((res)=>{
        const url = res.url.split('?')[0];
        this.imageUrl = url || ''
        this.isProgress = false
        this.$emit('change', this.imageUrl)
      }).finally(()=>{
        this.loading = false;
      })
    },

    // 设置裁剪的配置
    setVueCropperOptions() {
      this.$refs.VueCropperItem.options.autoCropWidth = this.autoCropWidth
      this.$refs.VueCropperItem.options.autoCropHeight = this.autoCropHeight
      this.$refs.VueCropperItem.options.fixedBox = this.fixedBox

      this.$refs.VueCropperItem.isCompress = this.isCompress // 是否压缩图片
      this.$refs.VueCropperItem.compress = this.compress // 压缩率
    }

  }
}
</script>

<style lang="scss" scoped>
.upload-box {
  .avatar-uploader-box {
    position: relative;
    line-height: 0;
    width: fit-content; //  收缩与包裹,收缩到合适
    .avatar {
      width: 176px;
      height: auto;
    }
    .avatar-uploader {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      &:hover {
        border-color: #409eff;
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        min-width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
      }
      .avatar {
        min-width: 178px;
        display: block;
      }
    }
    .progress-box {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 99%;
      height: 99%;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .avatar-uploader-popup {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.5);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 20px;
      border-radius: 6px;
      i {
        width: 30%;
        text-align: center;
        padding: 0 5%;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }
}
</style>
