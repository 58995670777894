<template>
  <div class="app-container">
    <div class="outer">
      <el-form label-width="100px">
        <el-form-item
          label="店铺名称"
          required
        >
          <el-input
            v-model="name"
            placeholder="请输入店铺名称"
            style="width:200px"
          />
        </el-form-item>
        <el-form-item
          label="店铺地址"
          required
        >
          <div class="store-address">
            <el-cascader
              v-model="siteValue"
              :props="siteOptions"
              placeholder="请选择省市区"
              :options="siteArr"
              clearable
            />
            <span class="space"></span>
            <el-input
              v-model="addressDetail"
              placeholder="请填写详细地址"
              style="max-width: 440px"
            />
          </div>
        </el-form-item>
        <el-form-item
          label="店铺头像"
          required
        >
          <load-img
            :data="data"
            :max-size="2097152"
          />
        </el-form-item>
        <el-form-item
          label="店铺招牌"
          required
        >
          <p class="remark">
            店铺招牌背景图片呈渐变显示，请在设计时注意整体效果。<br>
            尺寸为750×580，建议400KB左右，支持类型为jpg和png。
          </p>
          <cut-img
            :url="shopSign.url"
            @change="changeAvatar"
          />
        </el-form-item>
        <el-form-item
          label="店主头像"
          required
        >
          <p class="remark">
            将展示在“农民说”的农民信息区，建议尺寸80×80。
          </p>
          <load-img
            :data="headData"
            :max-size="2097152"
          />
        </el-form-item>
        <el-form-item
          label="店主简介"
          required
        >
          <el-input
            v-model="description"
            placeholder="请输入简介"
            style="width:200px"
          />
          <p class="remark">
            将展示在“农民说”的农民信息区，建议文字精简并突出优势。
          </p>
        </el-form-item>
        <el-form-item
          label="店主承诺"
          required
        >
          <el-input
            v-model="commitment"
            type="textarea"
            placeholder="请输入承诺语"
            style="width:200px"
            maxlength="30"
          />
          <p class="remark">
            仅30个字以内
          </p>
        </el-form-item>
        <el-form-item
          label="商家资质"
          required
        >
          <p class="remark">
            必传<b>《营业执照》</b>, 有的话请上传<b>《食品经营许可证》</b>
          </p>
          <load-img
            :data="aptitude"
            :max-size="3145728"
            max="5"
          />
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        style="width:110px;margin:50px 100px;"
        @click="submitStore"
      >
        提交
      </el-button>
      <el-button
        v-if="type === 'admin'"
        style="width:110px;"
        @click="backGo"
      >
        返回
      </el-button>
    </div>
  </div>
</template>

<script>
import loadImg from '@/components/common/loadImg'
import cutImg from '@/components/common/cutImg'
export default {
  name: 'EditStore',
  components: {
    loadImg,
    cutImg
  },
  data() {
    return {
      name: '', // 店铺名称
      type: '', // 内容类型
      suppliers_id: '', // 供应商id
      description: '', // 店铺简介
      data: {
        fileList: [],
        imgCode: []
      },
      shopSign: {
        url: '',
        imgCode: ''
      },
      headData: {
        fileList: [],
        imgCode: []
      },
      aptitude: {
        fileList: [],
        imgCode: []
      },
      commitment: '', // 店主承诺
      // 店铺地址
      siteOptions: {
        value: 'id',
        label: 'name',
        children: 'rows'
      },
      siteValue: [],
      siteArr: [],
      addressDetail: ''
    }
  },
  mounted() {
    this.getAddress()
    const { name, type, id, img, imgId } = this.$route.query
    if (type === 'admin') {
      this.name = name
      this.suppliers_id = id
      if (imgId) {
        this.data.fileList.push({ name: '店铺图片', url: img })
        this.data.imgCode.push(imgId)
      }
      this.type = type
    } else {
      this.getInfo()
    }
  },
  methods: {
    // 获取省市区
    getAddress() {
      this.$axios({
        method: 'get',
        url: this.$api.Address.get_third_address
      }).then((res) => {
        let r = res
        if (r.error === 0) {
          this.siteArr = r.data
        }
      })
    },
    // 图片
    changeAvatar(data) {
      this.shopSign.url = (data && data.url) || ''
      this.shopSign.imgCode = (data && data.image_no) || ''
    },
    // 获取用户信息
    getInfo() {
      this.$axios({
        method: 'post',
        url: this.$api.Store.store_detail,
        data: {
          suppliers_id: localStorage.getItem('suppliers_id')
        }
      }).then((res) => {
        let r = res || {}
        if (r.error === 0) {
          let info = r.data || {}
          this.name = info.title
          this.commitment = info.commitment || ''
          this.data.fileList.push({ name: '店铺头像', url: info.image_url })
          this.data.imgCode.push(info.image_id)
          this.description = info.description || ''
          this.shopSign.url = info.bg_image_url
          this.shopSign.imgCode = info.bg_image_id
          this.headData.fileList.push({ name: '店主头像', url: info.head_pic_url })
          this.headData.imgCode.push(info.head_pic)
          this.siteValue = [info.store_province, info.store_city, info.store_district]
          this.addressDetail = info.store_address
          info.store_cert_image_url.forEach(item => {
            this.aptitude.fileList.push({ name: '商家资质', url: item })
          })
          info.store_cert.split(',').forEach(item => {
            this.aptitude.imgCode.push(item)
          })
        }
      })
    },
    // 保存
    submitStore() {
      if (this.name === '' || this.data.imgCode.length === 0 || this.shopSign.imgCode === '' || this.headData.imgCode.length === 0 || this.description === '' || this.aptitude.imgCode.length === 0) {
        this.$message('缺少必填项')
        return false
      }
      if (this.siteValue.length === 0 || this.addressDetail === '') {
        this.$message('请补充店铺地址')
        return false
      }
      this.$axios.post(this.$api.saveStoreInfo, {
        title: this.name,
        image_id: this.data.imgCode.join(),
        suppliers_id: localStorage.getItem('suppliers_id'),
        bg_image_id: this.shopSign.imgCode,
        head_pic: this.headData.imgCode.join(),
        description: this.description,
        store_cert: this.aptitude.imgCode.join(','),
        commitment: this.commitment,
        province: this.siteValue[0],
        city: this.siteValue[1],
        district: this.siteValue[2],
        address: this.addressDetail
      }).then((res) => {
        let r = res
        if (r.error === 0) {
          this.$router.push('/store/supplier')
          this.$message('保存成功')
        } else {
          this.$message(r.error_msg)
        }
      })
    },

    backGo() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.remark {
  color: #999;
  &>b {
    color: #FF4949;
  }
}
.store-address {
  display: flex;
  .space {
    display: block;
    width: 20px;
  }
}
</style>
