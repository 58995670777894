var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload-box" },
    [
      _c(
        "div",
        { staticClass: "avatar-uploader-box" },
        [
          _vm.type === "avatar" && !_vm.imageUrl
            ? _c(
                "el-upload",
                {
                  ref: "avatarUploader",
                  staticClass: "avatar-uploader",
                  attrs: {
                    action: _vm.$api.nb_common.newUpload,
                    "show-file-list": false,
                    "on-change": _vm.handleAvatarChange,
                    "auto-upload": false,
                    accept: "image/*",
                  },
                },
                [
                  !_vm.imageUrl
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          staticStyle: { "line-height": "30px" },
                          attrs: {
                            "element-loading-text": "上传中",
                            "element-loading-spinner": "el-icon-loading",
                            "element-loading-background": "rgba(0, 0, 0, 0.8)",
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                        ]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm.imageUrl
            ? _c("img", {
                staticClass: "avatar",
                attrs: { src: _vm.imageUrl },
                on: {
                  mouseover: function ($event) {
                    $event.stopPropagation()
                    _vm.isShowPopup = true
                  },
                },
              })
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.imageUrl && _vm.isShowPopup,
                  expression: "imageUrl && isShowPopup",
                },
              ],
              staticClass: "avatar-uploader-popup",
              on: {
                mouseleave: function ($event) {
                  _vm.isShowPopup = false
                },
              },
            },
            [
              _c("i", {
                staticClass: "el-icon-zoom-in",
                on: {
                  click: function ($event) {
                    _vm.dialogVisible = true
                  },
                },
              }),
              _c("i", {
                staticClass: "el-icon-delete",
                on: { click: _vm.delImageUrl },
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "图片查看",
            center: "",
            visible: _vm.dialogVisible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [_c("img", { attrs: { width: "100%", src: _vm.imageUrl, alt: "" } })]
      ),
      _c("vue-cropper-item", {
        ref: "VueCropperItem",
        on: { confirm: _vm.confirmCropper },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }